import { Modal } from "ant-design-vue";
import { VueNode } from "ant-design-vue/es/_util/type";
import { defineStore } from "pinia";

export const useAlertStore = defineStore("alert", {
  state: () => ({
    type: "",
    message: "",
  }),
  actions: {
    success (message: string) {
      this.message = message;
      this.type = "success";
    },
    error (message: string) {
      this.message = message;
      this.type = "error";
    },
    warning (message: string) {
      this.message = message;
      this.type = "warning";
    },
    clear () {
      this.message = "";
      this.type = "";
    },
    errorModal (title: string, content: VueNode) {
      Modal.error({
        title: title || "Thông báo",
        content,
      });
    },
  },
  logger: false,
});
