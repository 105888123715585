import { TheAdvanceMediaFolderEnum } from "@domain/interfaces/theadvance-media/upload-interface";
import { IUploadImageToStatic, TheAdvanceMediaResponseData } from "@domain/interfaces/upload-interface";
import UploadRepository from "@domain/repositories/upload-repository";
import axios from "axios";
import { injectable } from "inversify";

@injectable()
export default class UploadRepositoryApiRepository implements UploadRepository {
  private UPLOAD_STATIC = "https://upload.ngocdunggroup.com.vn/upload/images?product=ZEMA_APP&token=de4fefbc0f4602388af2";

  async uploadImageToStatic (files: Array<File>): Promise<IUploadImageToStatic> {
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file1", file, file.name);
    });

    const response = await axios.post(this.UPLOAD_STATIC, formData, config);

    if (response.data) return response.data;

    return {
      error_code: 0,
      message: "",
      status: 0,
      data: [],
    };
  }

  async uploadToTheAdvanceMedia (folder: TheAdvanceMediaFolderEnum, files: Array<File>): Promise<TheAdvanceMediaResponseData> {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        product: gConfig.theadvanceMedia.product,
        token: gConfig.theadvanceMedia.token,
      },
    };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });

    const response = await axios.post(`${gConfig.theadvanceMedia.host}/theadvance/${folder}/upload`, formData, config);

    if (response.data && response.data.status === 200 && response.data.data) {
      return response.data.data;
    }

    return {} as TheAdvanceMediaResponseData;
  }

  async uploadChatFile (files: Array<File>): Promise<TheAdvanceMediaResponseData> {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        product: gConfig.theadvanceMedia.product,
        token: gConfig.theadvanceMedia.token,
      },
    };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });

    const response = await axios.post(`${gConfig.theadvanceMedia.host}/chat/upload`, formData, config);

    if (response.data && response.data.status === 200 && response.data.data) {
      return response.data.data;
    }

    return {} as TheAdvanceMediaResponseData;
  }
}
