/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import MiniApp from "@domain/entities/mini-app-entity";
import { IGetMiniAppDetail, IGetMiniAppList, IQueryMiniApp, UploadMiniAppBody } from "@domain/interfaces/mini-app-interface";

export default abstract class MiniAppRepository {
  async getList (query?: IQueryMiniApp): Promise<IGetMiniAppList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: MiniApp): Promise<IGetMiniAppDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<MiniApp>): Promise<IGetMiniAppDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetMiniAppDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async upload (data: UploadMiniAppBody): Promise<IGetMiniAppDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeTag (id: string, name: string): Promise<IGetMiniAppDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
