/* eslint-disable @typescript-eslint/no-unused-vars */
import Notification from "@domain/entities/notification-entity";
import {
  IGetNotificationDetail,
  IGetNotificationList,
  IGetNotificationScreenList,
  IQueryNotification,
  IRemoveNotificationBody
} from "@domain/interfaces/notification-interface";
import NotificationRepository from "@domain/repositories/notification-repository";
import { injectable } from "inversify";

@injectable()
export default class NotificationMockupRepository implements NotificationRepository {
  private item = {
    id: "123456",
    title: "",
    content: "",
    employee_id: "",
    type: "",
    created_by: "",
    created_at: "",
    updated_at: "",
  };

  async getList (query: IQueryNotification): Promise<IGetNotificationList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async createBySegment (body: Notification): Promise<IGetNotificationDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: IRemoveNotificationBody): Promise<IGetNotificationDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async getScreenList (): Promise<IGetNotificationScreenList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: {
        items: [
          { text: "internalNews", key: "/internalNews", name: "Tin tức nội bộ" },
          { text: "notifications", key: "/notifications", name: "Thông báo" },
          { text: "profile", key: "/profile", name: "Profile" },
        ],
        total: 0,
        limit: 0,
      },
    };
  }
}
