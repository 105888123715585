/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import BookingMealGroup from "@domain/entities/booking-meal-group-entity";
import {
  IGetBookingGroupEmployee,
  IGetBookingMealGroupDetail,
  IGetBookingMealGroupList,
  IQueryBookingMealGroup,
  IUpdateBookingGroupEmployee
} from "@domain/interfaces/booking-meal-group-interface";

export default abstract class BookingMealGroupRepository {
  async getList (query?: IQueryBookingMealGroup): Promise<IGetBookingMealGroupList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: BookingMealGroup): Promise<IGetBookingMealGroupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<BookingMealGroup>): Promise<IGetBookingMealGroupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetBookingMealGroupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getEmployee (id: string): Promise<IGetBookingGroupEmployee> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async updateEmployee (id: string, action: "add" | "remove", body: { employees: string[] }): Promise<IUpdateBookingGroupEmployee> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
