import { DEFAULT_LIMIT } from "@common/constant";
import Segment from "@domain/entities/segment-entity";
import { IGetSegmentDetail, IGetSegmentList, IGetSegmentUserList, IQuerySegment } from "@domain/interfaces/segment-interface";
import SegmentRepository from "@domain/repositories/segment-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class SegmentApiRepository implements SegmentRepository {
  private apiService = new ApiService();

  async getSegmentUser (query: IQuerySegment): Promise<IGetSegmentUserList> {
    const result = await this.apiService.get("/segment/user", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async getList (query: IQuerySegment): Promise<IGetSegmentList> {
    const result = await this.apiService.get("/segment/list", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: Segment): Promise<IGetSegmentDetail> {
    const result = await this.apiService.post("/segment", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<Segment>): Promise<IGetSegmentDetail> {
    const result = await this.apiService.put(`/segment/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetSegmentDetail> {
    const result = await this.apiService.remove(`/segment/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
