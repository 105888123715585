/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { TheAdvanceMediaFolderEnum } from "@domain/interfaces/theadvance-media/upload-interface";
import { IUploadImageToStatic, TheAdvanceMediaResponseData } from "@domain/interfaces/upload-interface";

export default abstract class UploadRepository {
  async uploadImageToStatic (files: Array<File>): Promise<IUploadImageToStatic> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async uploadToTheAdvanceMedia (folder: TheAdvanceMediaFolderEnum, files: Array<File>): Promise<TheAdvanceMediaResponseData> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async uploadChatFile (files: Array<File>): Promise<TheAdvanceMediaResponseData> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
