/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";

import ErrorCode from "@common/kernel/error-code";
import { CreateMessageBySegmentBody, CreateMessageBySegmentResponse } from "@domain/interfaces/chat/message-interface";

export default abstract class MessageRepository {
  async createBySegment (body: CreateMessageBySegmentBody): Promise<CreateMessageBySegmentResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
