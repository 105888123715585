/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import NotificationSchedule from "@domain/entities/notification-schedule-entity";
import {
  IGetNotificationScheduleDetail,
  IGetNotificationScheduleList,
  IQueryNotificationSchedule
} from "@domain/interfaces/notification-schedule-interface";

export default abstract class NotificationScheduleRepository {
  async getList (query?: IQueryNotificationSchedule): Promise<IGetNotificationScheduleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: NotificationSchedule): Promise<IGetNotificationScheduleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<NotificationSchedule>): Promise<IGetNotificationScheduleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetNotificationScheduleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async sendImmediately (id: string): Promise<IGetNotificationScheduleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
