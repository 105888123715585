import { DEFAULT_LIMIT } from "@common/constant";
import NotificationSchedule from "@domain/entities/notification-schedule-entity";
import {
  IGetNotificationScheduleDetail,
  IGetNotificationScheduleList,
  IQueryNotificationSchedule
} from "@domain/interfaces/notification-schedule-interface";
import NotificationScheduleRepository from "@domain/repositories/notification-schedule-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class NotificationScheduleApiRepository implements NotificationScheduleRepository {
  private api = new ApiService();

  async getList (query: IQueryNotificationSchedule): Promise<IGetNotificationScheduleList> {
    const result = await this.api.get("/notification-schedule", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: NotificationSchedule): Promise<IGetNotificationScheduleDetail> {
    const result = await this.api.post("/notification-schedule", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<NotificationSchedule>): Promise<IGetNotificationScheduleDetail> {
    const result = await this.api.put(`/notification-schedule/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetNotificationScheduleDetail> {
    const result = await this.api.remove(`/notification-schedule/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async sendImmediately (id: string): Promise<IGetNotificationScheduleDetail> {
    const result = await this.api.post(`/notification-schedule/${id}/send-immediately`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
