/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import LuckyMoney from "@domain/entities/lucky-money-entity";
import {
  ICreateLuckyMoneyBody,
  ICreateMoneyResponse,
  IGetLuckyMoneyDetail,
  IGetLuckyMoneyList,
  IQueryLuckyMoney
} from "@domain/interfaces/lucky-money-interface";

export default abstract class LuckyMoneyRepository {
  async getList (query?: IQueryLuckyMoney): Promise<IGetLuckyMoneyList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: ICreateLuckyMoneyBody): Promise<ICreateMoneyResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<LuckyMoney>): Promise<IGetLuckyMoneyDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetLuckyMoneyDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async export (query: IQueryLuckyMoney): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async reset (): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
