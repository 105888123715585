export const routesName = {
  login: "login",
  root: "root",
  notFound: "notFound",
  admin: {
    event: "event",
    miniApp: "miniApp",
    bookingMeal: "bookingMeal",
    bookingMealFeedback: "bookingMealFeedback",
    bookingMealGroup: "bookingMealGroup",
    bookingMealConfig: "bookingMealConfig",

    config: "config",
    testPhone: "testPhone",
    segment: "segment",
    appVersion: "appVersion",
    homeMenu: "homeMenu",
    popup: "popup",
    luckyMoney: "luckyMoney",

    sendMessage: "sendMessage",
    pushNotification: "pushNotification",
    notificationSchedule: "notificationSchedule",
    notification: "notification",

    customerRecord: "customerRecord",
    checkIn: "checkIn",
    user: "user",
    home: "home",
    account: "account",
    rule: "rule",
    role: "role",

    //CAll
    callQueue: "callQueue",
    callNumber: "callNumber",
    callAgent: "callAgent",

    //Chat
    conversation: "conversation",
  },
};
