export const ACTION = {
  update: "update",
  create: "create",
  delete: "delete",
  submit: "submit",
  view: "view",
  detail: "detail",
  sendToGroup: "sendToGroup",
  setting: "setting",
  save: "save",
  search: "search",
  select: "select",
  changePage: "changePage",
  upload: "upload",
  query: "query",
  saveContact: "saveContact",
  call: "call",
  userChat: "userChat",
  userSocial: "userSocial",
  addUser: "addUser",
  export: "export",
  tagManager: "tagManager",
  sendInvite: "sendInvite",
  reset: "reset",
};

export const DEFAULT_LIMIT = 10;
