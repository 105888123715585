/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Popup from "@domain/entities/popup-entity";
import { IGetPopupDetail, IGetPopupList, IQueryPopup } from "@domain/interfaces/popup-interface";

export default abstract class PopupRepository {
  async getList (query?: IQueryPopup): Promise<IGetPopupList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: Popup): Promise<IGetPopupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<Popup>): Promise<IGetPopupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetPopupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async reset (id: string): Promise<IGetPopupDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
