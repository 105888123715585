/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import {
  IAddConversationMember,
  IAddConversationMemberBody,
  IGetConversationList,
  IGetConversationMember,
  IGetConversationMemberQuery,
  IGetConversationQuery,
  IRemoveConversationMember,
  IRemoveConversationMemberBody
} from "@domain/interfaces/chat/conversation-interface";

import ErrorCode from "@common/kernel/error-code";

export default abstract class ConversationRepository {
  async getConversationList (query: IGetConversationQuery): Promise<IGetConversationList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeConversationMember (body: IRemoveConversationMemberBody): Promise<IRemoveConversationMember> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async addConversationMember (body: IAddConversationMemberBody): Promise<IAddConversationMember> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getConversationMember (id: string, query: IGetConversationMemberQuery): Promise<IGetConversationMember> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
