import { DEFAULT_LIMIT } from "@common/constant";
import Popup from "@domain/entities/popup-entity";
import { IGetPopupDetail, IGetPopupList, IQueryPopup } from "@domain/interfaces/popup-interface";
import PopupRepository from "@domain/repositories/popup-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class PopupApiRepository implements PopupRepository {
  private api = new ApiService();

  async getList (query: IQueryPopup): Promise<IGetPopupList> {
    const result = await this.api.get("/popup", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: Popup): Promise<IGetPopupDetail> {
    const result = await this.api.post("/popup", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<Popup>): Promise<IGetPopupDetail> {
    const result = await this.api.put(`/popup/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetPopupDetail> {
    const result = await this.api.remove(`/popup/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async reset (id: string): Promise<IGetPopupDetail> {
    const result = await this.api.put(`/popup/${id}/reset`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
