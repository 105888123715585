/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Notification from "@domain/entities/notification-entity";
import {
  IGetNotificationDetail,
  IGetNotificationList,
  IGetNotificationScreenList,
  IQueryNotification,
  IRemoveNotificationBody
} from "@domain/interfaces/notification-interface";

export default abstract class NotificationRepository {
  async getList (query?: IQueryNotification): Promise<IGetNotificationList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  async createBySegment (data: Notification): Promise<IGetNotificationDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (body: IRemoveNotificationBody): Promise<IGetNotificationDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getScreenList (): Promise<IGetNotificationScreenList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
