import { Container } from "inversify";
import "reflect-metadata";

import NotificationScheduleRepository from "@domain/repositories/notification-schedule-repository";
import NotificationScheduleRepositoryApi from "@infrastructure/repositories/api/notification-schedule-api-repository";

import EventRepository from "@domain/repositories/event-repository";
import EventRepositoryApi from "@infrastructure/repositories/api/event-api-repository";

import LuckyMoneyRepository from "@domain/repositories/lucky-money-repository";
import LuckyMoneyRepositoryApi from "@infrastructure/repositories/api/lucky-money-api-repository";

import PopupRepository from "@domain/repositories/popup-repository";
import PopupRepositoryApi from "@infrastructure/repositories/api/popup-api-repository";

import MiniAppRepository from "@domain/repositories/mini-app-repository";
import MiniAppRepositoryApi from "@infrastructure/repositories/api/mini-app-api-repository";

import BookingMealGroupRepository from "@domain/repositories/booking-meal-group-repository";
import BookingMealGroupRepositoryApi from "@infrastructure/repositories/api/booking-meal-group-api-repository";
import BookingMealGroupRepositoryMockup from "@infrastructure/repositories/mockup/booking-meal-group-mockup-repository";

import CallAgentRepository from "@domain/repositories/call-agent-repository";
import CallAgentRepositoryApi from "@infrastructure/repositories/api/call-agent-api-repository";
import CallAgentRepositoryMockup from "@infrastructure/repositories/mockup/call-agent-mockup-repository";

import CallNumberRepository from "@domain/repositories/call-number-repository";
import CallNumberRepositoryApi from "@infrastructure/repositories/api/call-number-api-repository";
import CallNumberRepositoryMockup from "@infrastructure/repositories/mockup/call-number-mockup-repository";

import CallQueueRepository from "@domain/repositories/call-queue-repository";
import CallQueueRepositoryApi from "@infrastructure/repositories/api/call-queue-api-repository";
import CallQueueRepositoryMockup from "@infrastructure/repositories/mockup/call-queue-mockup-repository";

import AccountRepository from "@domain/repositories/account-repository";
import AccountRepositoryApi from "@infrastructure/repositories/api/account-api-repo";
import AccountRepositoryMockup from "@infrastructure/repositories/mockup/account-mockup-repo";

import RoleRepository from "@domain/repositories/role-repository";
import RoleRepositoryApi from "@infrastructure/repositories/api/role-api-repo";
import RoleRepositoryMockup from "@infrastructure/repositories/mockup/role-mockup-repo";

import RuleRepository from "@domain/repositories/rule-repository";
import RuleRepositoryApi from "@infrastructure/repositories/api/rule-api-repository";
import RuleRepositoryMockup from "@infrastructure/repositories/mockup/rule-mockup-repository";

import AppVersionRepository from "@domain/repositories/app-version-repository";
import AppVersionRepositoryApi from "@infrastructure/repositories/api/app-version-api-repository";
import AppVersionRepositoryMockup from "@infrastructure/repositories/mockup/app-version-mockup-repository";

import UserRepository from "@domain/repositories/user-repository";
import UserRepositoryApi from "@infrastructure/repositories/api/user-api-repository";
import UserRepositoryMockup from "@infrastructure/repositories/mockup/user-mockup-repository";

import CheckInRepository from "@domain/repositories/check-in-repository";
import CheckInRepositoryApi from "@infrastructure/repositories/api/check-in-api-repository";
import CheckInRepositoryMockup from "@infrastructure/repositories/mockup/check-in-mockup-repository";

import HomeMenuRepository from "@domain/repositories/home-menu-repository";
import HomeMenuRepositoryApi from "@infrastructure/repositories/api/home-menu-api-repository";
import HomeMenuRepositoryMockup from "@infrastructure/repositories/mockup/home-menu-mockup-repository";

import FoodBookingGroupRepository from "@domain/repositories/food-booking-group-repository";
import FoodBookingGroupRepositoryApi from "@infrastructure/repositories/api/food-booking-group-api-repository";
import FoodBookingGroupRepositoryMockup from "@infrastructure/repositories/mockup/food-booking-group-mockup-repository";

import CustomerRecordRepository from "@domain/repositories/customer-record-repository";
import CustomerRecordRepositoryApi from "@infrastructure/repositories/api/customer-record-api-repository";
import CustomerRecordRepositoryMockup from "@infrastructure/repositories/mockup/customer-record-mockup-repository";

import TestPhoneRepository from "@domain/repositories/test-phone-repository";
import TestPhoneRepositoryApi from "@infrastructure/repositories/api/test-phone-api-repository";
import TestPhoneRepositoryMockup from "@infrastructure/repositories/mockup/test-phone-mockup-repository";

import NotificationRepository from "@domain/repositories/notification-repository";
import NotificationRepositoryApi from "@infrastructure/repositories/api/notification-api-repository";
import NotificationRepositoryMockup from "@infrastructure/repositories/mockup/notification-mockup-repository";

import SegmentRepository from "@domain/repositories/segment-repository";
import SegmentRepositoryApi from "@infrastructure/repositories/api/segment-api-repository";
import SegmentRepositoryMockup from "@infrastructure/repositories/mockup/segment-mockup-repository";

import UploadRepository from "@domain/repositories/upload-repository";
import UploadRepositoryApi from "@infrastructure/repositories/api/upload-api-repository";
import UploadRepositoryMockup from "@infrastructure/repositories/mockup/upload-mockup-repository";

import ConfigRepository from "@domain/repositories/config-repository";
import ConfigRepositoryApi from "@infrastructure/repositories/api/config-api-repository";
import ConfigRepositoryMockup from "@infrastructure/repositories/mockup/config-mockup-repository";

import ConversationRepository from "@domain/repositories/chat/conversation-repository";
import PostRepository from "@domain/repositories/social/post-repository";
import ConversationApiRepository from "@infrastructure/repositories/api/chat/conversation-api-repository";
import SocialPostApiRepository from "@infrastructure/repositories/api/social/post-api-repository";

import BookingMealRepository from "@domain/repositories/booking-meal-repository";
import MessageRepository from "@domain/repositories/chat/message-repository";
import BookingMealRepositoryApi from "@infrastructure/repositories/api/booking-meal-api-repository";
import MessageApiRepository from "@infrastructure/repositories/api/chat/message-api-repository";
// import BookingMealMockupRepository from "@infrastructure/repositories/mockup/booking-meal-mockup-repository";
const repository = new Container();

if (process.env.VUE_APP_REPOSITORY === "api") {
  repository.bind<NotificationScheduleRepository>(NotificationScheduleRepository).to(NotificationScheduleRepositoryApi);
  repository.bind<EventRepository>(EventRepository).to(EventRepositoryApi);
  repository.bind<LuckyMoneyRepository>(LuckyMoneyRepository).to(LuckyMoneyRepositoryApi);
  repository.bind<PopupRepository>(PopupRepository).to(PopupRepositoryApi);
  repository.bind<MiniAppRepository>(MiniAppRepository).to(MiniAppRepositoryApi);
  repository.bind<BookingMealGroupRepository>(BookingMealGroupRepository).to(BookingMealGroupRepositoryApi);
  repository.bind<CallAgentRepository>(CallAgentRepository).to(CallAgentRepositoryApi);
  repository.bind<CallNumberRepository>(CallNumberRepository).to(CallNumberRepositoryApi);
  repository.bind<CallQueueRepository>(CallQueueRepository).to(CallQueueRepositoryApi);
  repository.bind<ConfigRepository>(ConfigRepository).to(ConfigRepositoryApi);
  repository.bind<SegmentRepository>(SegmentRepository).to(SegmentRepositoryApi);
  repository.bind<NotificationRepository>(NotificationRepository).to(NotificationRepositoryApi);
  repository.bind<TestPhoneRepository>(TestPhoneRepository).to(TestPhoneRepositoryApi);
  repository.bind<CustomerRecordRepository>(CustomerRecordRepository).to(CustomerRecordRepositoryApi);
  repository.bind<FoodBookingGroupRepository>(FoodBookingGroupRepository).to(FoodBookingGroupRepositoryApi);
  repository.bind<BookingMealRepository>(BookingMealRepository).to(BookingMealRepositoryApi);
  repository.bind<HomeMenuRepository>(HomeMenuRepository).to(HomeMenuRepositoryApi);
  repository.bind<CheckInRepository>(CheckInRepository).to(CheckInRepositoryApi);
  repository.bind<UserRepository>(UserRepository).to(UserRepositoryApi);
  repository.bind<AppVersionRepository>(AppVersionRepository).to(AppVersionRepositoryApi);
  repository.bind<RuleRepository>(RuleRepository).to(RuleRepositoryApi);
  repository.bind<AccountRepository>(AccountRepository).to(AccountRepositoryApi);
  repository.bind<RoleRepository>(RoleRepository).to(RoleRepositoryApi);
  repository.bind<UploadRepository>(UploadRepository).to(UploadRepositoryApi);
  repository.bind<ConversationRepository>(ConversationRepository).to(ConversationApiRepository);
  repository.bind<PostRepository>(PostRepository).to(SocialPostApiRepository);
  repository.bind<MessageRepository>(MessageRepository).to(MessageApiRepository);
} else {
  // repository.bind<MiniAppRepository>(MiniAppRepository).to(MiniAppRepositoryMockup);
  repository.bind<BookingMealGroupRepository>(BookingMealGroupRepository).to(BookingMealGroupRepositoryMockup);
  repository.bind<CallAgentRepository>(CallAgentRepository).to(CallAgentRepositoryMockup);
  repository.bind<CallNumberRepository>(CallNumberRepository).to(CallNumberRepositoryMockup);
  repository.bind<CallQueueRepository>(CallQueueRepository).to(CallQueueRepositoryMockup);
  repository.bind<ConfigRepository>(ConfigRepository).to(ConfigRepositoryMockup);
  repository.bind<SegmentRepository>(SegmentRepository).to(SegmentRepositoryMockup);
  // repository.bind<BookingMealRepository>(BookingMealRepository).to(BookingMealMockupRepository);
  repository.bind<NotificationRepository>(NotificationRepository).to(NotificationRepositoryMockup);
  repository.bind<TestPhoneRepository>(TestPhoneRepository).to(TestPhoneRepositoryMockup);
  repository.bind<CustomerRecordRepository>(CustomerRecordRepository).to(CustomerRecordRepositoryMockup);
  repository.bind<FoodBookingGroupRepository>(FoodBookingGroupRepository).to(FoodBookingGroupRepositoryMockup);
  repository.bind<HomeMenuRepository>(HomeMenuRepository).to(HomeMenuRepositoryMockup);
  repository.bind<CheckInRepository>(CheckInRepository).to(CheckInRepositoryMockup);
  repository.bind<UserRepository>(UserRepository).to(UserRepositoryMockup);
  repository.bind<AppVersionRepository>(AppVersionRepository).to(AppVersionRepositoryMockup);
  repository.bind<RuleRepository>(RuleRepository).to(RuleRepositoryMockup);
  repository.bind<AccountRepository>(AccountRepository).to(AccountRepositoryMockup);
  repository.bind<RoleRepository>(RoleRepository).to(RoleRepositoryMockup);
  repository.bind<UploadRepository>(UploadRepository).to(UploadRepositoryMockup);
}

export default repository;
