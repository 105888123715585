import { DEFAULT_LIMIT } from "@common/constant";
import LuckyMoney from "@domain/entities/lucky-money-entity";
import {
  ICreateLuckyMoneyBody,
  ICreateMoneyResponse,
  IGetLuckyMoneyDetail,
  IGetLuckyMoneyList,
  IQueryLuckyMoney
} from "@domain/interfaces/lucky-money-interface";
import LuckyMoneyRepository from "@domain/repositories/lucky-money-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class LuckyMoneyApiRepository implements LuckyMoneyRepository {
  private api = new ApiService();

  async getList (query: IQueryLuckyMoney): Promise<IGetLuckyMoneyList> {
    const result = await this.api.get("/lucky-money", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: ICreateLuckyMoneyBody): Promise<ICreateMoneyResponse> {
    const result = await this.api.post("/lucky-money", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<LuckyMoney>): Promise<IGetLuckyMoneyDetail> {
    const result = await this.api.put(`/lucky-money/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetLuckyMoneyDetail> {
    const result = await this.api.remove(`/lucky-money/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async export (query: IQueryLuckyMoney): Promise<any> {
    await this.api.save("/lucky-money/export", "GET", "lucky-money.xlsx", query);

    return;
  }

  async reset (): Promise<any> {
    const result = await this.api.put("/lucky-money/reset", {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
