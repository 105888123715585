import { CreateMessageBySegmentBody, CreateMessageBySegmentResponse } from "@domain/interfaces/chat/message-interface";
import { injectable } from "inversify";

import MessageRepository from "@domain/repositories/chat/message-repository";
import ApiService from "@infrastructure/network/api";

@injectable()
export default class MessageApiRepository implements MessageRepository {
  private apiService = new ApiService();

  async createBySegment (body: CreateMessageBySegmentBody): Promise<CreateMessageBySegmentResponse> {
    const result = await this.apiService.post("/chat/message/segment", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
