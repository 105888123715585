import { DEFAULT_LIMIT } from "@common/constant";
import MiniApp from "@domain/entities/mini-app-entity";
import { IGetMiniAppDetail, IGetMiniAppList, IQueryMiniApp, UploadMiniAppBody } from "@domain/interfaces/mini-app-interface";
import MiniAppRepository from "@domain/repositories/mini-app-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class MiniAppApiRepository implements MiniAppRepository {
  private api = new ApiService();

  async getList (query: IQueryMiniApp): Promise<IGetMiniAppList> {
    const result = await this.api.get("/mini-app", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: MiniApp): Promise<IGetMiniAppDetail> {
    const result = await this.api.post("/mini-app", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<MiniApp>): Promise<IGetMiniAppDetail> {
    const result = await this.api.put(`/mini-app/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetMiniAppDetail> {
    const result = await this.api.remove(`/mini-app/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async upload (data: UploadMiniAppBody): Promise<IGetMiniAppDetail> {
    const formData = new FormData();
    formData.append("files", data.files, data.files.name);

    const result = await this.api.post(`/mini-app/${data.id}/tags/${data.name}/upload`, formData);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async removeTag (id: string, name: string): Promise<IGetMiniAppDetail> {
    const result = await this.api.remove(`/mini-app/${id}/tags/${name}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
