/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import { TheAdvanceMediaFolderEnum } from "@domain/interfaces/theadvance-media/upload-interface";
import { IUploadImageToStatic, TheAdvanceMediaResponseData } from "@domain/interfaces/upload-interface";
import { injectable } from "inversify";

import ErrorCode from "@common/kernel/error-code";
import UploadRepository from "@domain/repositories/upload-repository";

@injectable()
export default class UploadRepositoryMockupRepository implements UploadRepository {
  async uploadImageToStatic (files: Array<File>): Promise<IUploadImageToStatic> {
    return {
      error_code: 1,
      message: "OK",
      status: 1,
      data: [
        {
          url: "",
          id: "",
        },
      ],
    };
  }

  async uploadToTheAdvanceMedia (folder: TheAdvanceMediaFolderEnum, files: Array<File>): Promise<TheAdvanceMediaResponseData> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async uploadChatFile (files: Array<File>): Promise<TheAdvanceMediaResponseData> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
